import axios from 'axios'
import React, { Suspense, useEffect } from 'react'
import { env } from '../config'
import * as Actions from "../redux/actions"
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import BlogCardSection from '../components/BlogCardSection'


const Whitepaper = () => {

    const dispatch = useDispatch()

    const whitePaperData: any = useSelector((state: any) => state?.Page?.WhitePaperContent)
    const whitePaperPageContent: any = useSelector((state: any) => state?.Page?.WhitePaperPageContent)



    let url = "/api/whitepaper-page-content?populate=deep"
    let action = Actions.WhitePaperpageAction
    let url1 = "/api/whitepapers?populate=deep"
    let action1 = Actions.WhitePaperContent



    useEffect(() => {

        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });



        if (Object.keys(whitePaperData).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url}`)
                        .then(function (response) {
                            console.log("response.data.data.attributes", response.data.data.attributes);
                            dispatch(action(response?.data?.data?.attributes))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        if (Object.keys(whitePaperPageContent).length === 0) {
            setTimeout(() => {
                (async () => {
                    await axios.get(`${env}${url1}`)
                        .then(function (response) {
                            dispatch(action1(response?.data?.data))

                        })
                        .catch((error: any) => {
                            console.log("error", error);
                        });
                })()
            }, 0);
        }
        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [dispatch])





    console.log("HOOMMRR", whitePaperData);


    console.log("BlogContent", whitePaperPageContent)

    return (
        <Suspense fallback={<Loader />}>
            <BlogCardSection BlogPageIntro={whitePaperPageContent?.BlogPageIntro} BlogPages={whitePaperData} />
        </Suspense>
    )
}

export default Whitepaper