import React from "react";
import { useInView } from "react-intersection-observer";
import { env } from "../config";
import { useNavigate } from "react-router";

function HRMnestTitleSection(props: any) {
  const {
    Title1,
    Title2,
    Description1,
    Description2,
    ButtonText,
    url,
    logo,
    link,
    position = "flex-col-reverse flex lg:flex-row lg:justify-between",
    imgPosition,
    altText,
  } = props;
  // flex-col-reverse flex lg:flex-row lg:justify-between
  const { ref, inView } = useInView({
    // triggerOnce: true,
    threshold: 0,
  });
  console.log("alttext", altText, logo);
  const navigate = useNavigate();
  const openInNewTab = (url: any) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div>
      <div
        className={`px-5 py-7 lg:px-10 xl:px-20 lg:py-14 font-custom  text-black-75 ${position}`}
      >
        <div ref={ref} className="lg:w-1/2 flex flex-col justify-center">
          <div
            className={`text-3xl md:text-4xl lg:text-5xl   font-custom font-bold`}
          >
            {logo?.data ? (
              <img
                className="w-[150px] md:w-[220px]  md:my-0"
                src={`${env}${logo?.data?.attributes?.url}`}
                alt="logo"
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: Title1 }} />
            )}
          </div>
          <div
            className={`text-3xl md:text-4xl lg:text-5xl md:mt-3  font-custom font-bold`}
          >
            {inView ? (
              <div dangerouslySetInnerHTML={{ __html: Title2 }} />
            ) : (
              <div
                className={` text-2xl md:text-4xl lg:text-5xl  font-custom font-bold`}
              >
                Development
              </div>
            )}
          </div>

          <div className={`lg:text-lg md:text-base mt-3  font-normal pt-3`}>
            {Description1}
          </div>
          <div className={`lg:text-lg md:text-base   font-normal pt-3`}>
            {Description2}
          </div>

          {ButtonText && (
            <button
              onClick={() => {
                link?.includes("https")
                  ? openInNewTab(link)
                  : link
                  ? navigate(link)
                  : openInNewTab("https://hrmnest.com/");
              }}
              className="mx-auto sm:mx-0  w-max my-5 rounded-[30px] flex items-center  bg-[#616161] text-white border border-[#616161] hover:bg-white hover:text-[#616161] hover:border hover:border-[#616161] px-4 py-2"
            >
              {ButtonText}
            </button>
          )}
        </div>
        {url ? (
          <img
            alt={altText}
            className={`${imgPosition}`}
            src={`${env}${url}`}
          />
        ) : null}
      </div>
    </div>
  );
}

export default HRMnestTitleSection;
