import React, { useState } from 'react'
import { env } from '../config'
import BlogCard from './BlogCard'

function BlogCardSection(props: any) {
    const { BlogPages, BlogPageIntro } = props
    const [activePage, setactivePage] = useState(1)


    let newArray = Array.from({ length: Math.ceil(BlogPages?.length / 6) }, (value, index) => index + 1);

    console.log("arraymethod", newArray[newArray.length - 1], BlogPages?.length, activePage)


    const [searchValue, setsearchValue] = useState("")
    const [containsSearchValue, setcontainsSearchValue] = useState(true)






    const changeCurrentlyDisplayedCards = (node: any) => {
        console.log("nodedde", node)

        if (searchValue.toString().trim().toLowerCase() === "" || null || undefined) {
            setcontainsSearchValue(true)


        } else if (searchValue) {
            let val = false;
            if (node?.attributes?.BlogHeader.MainTitle.toString().trim().toLowerCase().includes(searchValue.trim().toLowerCase())) {
                val = true;

            }
            return val


        }
    }






    return (<>
        <div style={{ backgroundImage: `url(${env}${BlogPageIntro?.Image?.data?.attributes?.url})` }} className={` bg-no-repeat bg-cover text-white h-72 md:h-[400px] lg:h-[537px] bg-center flex flex-col justify-center items-center gap-10`}>
            <div data-aos="zoom-out" className=' font-custom font-bold text-3xl md:text-4xl xl:text-5xl'>
                {BlogPageIntro?.Title}
            </div>
            <div className='relative md:w-[420px] '>
                <form className='w-full flex flex-col items-center'>
                    <input onChange={(e: any) => { setsearchValue(e.target.value) }} type="search" className='icon rounded-[50px] p-2 w-full text-black-75 pl-[10%] ' value={searchValue} placeholder='    Search here...' />
                    <svg className='absolute top-[14%] left-[3%]' width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5427 18.0769L10.2619 11.7961C9.76195 12.2089 9.18695 12.532 8.53695 12.7653C7.88695 12.9987 7.21451 13.1153 6.51965 13.1153C4.81048 13.1153 3.36396 12.5236 2.1801 11.34C0.99623 10.1564 0.404297 8.71029 0.404297 7.00157C0.404297 5.29284 0.99608 3.84616 2.17965 2.66154C3.36321 1.47694 4.80936 0.884644 6.5181 0.884644C8.22681 0.884644 9.67348 1.47658 10.8581 2.66044C12.0427 3.84431 12.635 5.29083 12.635 6.99999C12.635 7.71409 12.5151 8.39614 12.2754 9.04614C12.0356 9.69614 11.7158 10.2615 11.3158 10.7423L17.5965 17.0231L16.5427 18.0769ZM6.51965 11.6154C7.80811 11.6154 8.89946 11.1683 9.7937 10.274C10.6879 9.37981 11.135 8.28846 11.135 6.99999C11.135 5.71153 10.6879 4.62018 9.7937 3.72594C8.89946 2.83171 7.80811 2.38459 6.51965 2.38459C5.23118 2.38459 4.13983 2.83171 3.2456 3.72594C2.35138 4.62018 1.90427 5.71153 1.90427 6.99999C1.90427 8.28846 2.35138 9.37981 3.2456 10.274C4.13983 11.1683 5.23118 11.6154 6.51965 11.6154Z" fill="#404041" />
                    </svg>

                    <button onClick={(e: any) => { e.preventDefault(); setcontainsSearchValue(false) }} className=' rounded-[50px] mt-3 bg-[#616161] w-max text-white px-3 font-custom text-base py-2'>Search</button>
                </form>

            </div>
        </div >
        <div data-aos="fade-up" className={`py-14 md:grid  ${activePage === newArray[newArray.length - 1] ? "grid-cols-3 grid-rows-1" : "md:grid-cols-3 md:grid-rows-2"} gap-6  place-items-center px-3 sm:px-6  md:px-5 lg:px-20`}>
            {containsSearchValue ?
                BlogPages?.sort((a: any, b: any) => {
                    a = a?.attributes?.BlogHeader?.Date.split('/').reverse().join('');
                    b = b?.attributes?.BlogHeader?.Date.split('/').reverse().join('');
                    return b > a ? 1 : b < a ? -1 : 0;
                }).filter((node: any, index: number) => activePage === 1 ? index < 6 : activePage === 2 ? (index > 6) && (index <= 12) : activePage === 3 ? index > 12 && index <= 18 : activePage === 4 ? index > 18 && index <= 24 : activePage === 5 ? index > 24 && index <= 30 : activePage === 6 ? index > 30 && index <= 36 : null).map((node: any, index: number) => {
                    console.log("node22", node)
                    return (
                        <BlogCard
                            key={node?.attributes?.BlogHeader?.MainTitle}
                            id={node?.id}
                            index={index} date={node?.attributes?.BlogHeader?.Date} Heading={node?.attributes?.BlogHeader?.MainTitle} src={`${env}${node?.attributes?.BlogHeader?.Image?.data?.attributes?.url}`}
                        />
                    )
                }) :
                BlogPages?.sort((a: any, b: any) => { return b?.attributes?.BlogHeader?.Date.split("/")[2] - a?.attributes?.BlogHeader?.Date.split("/")[2] }).filter((node: any, index: number) => { return changeCurrentlyDisplayedCards(node) }).map((node: any, index: number) => {
                    console.log("node234", node)
                    return (
                        <BlogCard
                            key={node?.attributes?.BlogHeader?.MainTitle}
                            id={node?.id}
                            index={index} date={node?.attributes?.BlogHeader?.Date} Heading={node?.attributes?.BlogHeader?.MainTitle} src={`${env}${node?.attributes?.BlogHeader?.Image?.data?.attributes?.url}`}
                        />
                    )
                })


            }
            {/* 
            <div className='col-span-3 font-custom text-black-75 font-semibold flex justify-center items-center h-full '>
                No results available
            </div> */}

        </div>


        <div>
            <div className='w-max flex  mx-auto mb-7 gap-2 '>

                {activePage === 1 ? <svg className='cursor-not-allowed' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <rect width="32" height="32" rx="4" fill="#919EAB" />
                        <path d="M20.1602 11.41L15.5802 16L20.1602 20.59L18.7502 22L12.7502 16L18.7502 10L20.1602 11.41Z" fill="#C4CDD5" />
                    </g>
                </svg> : <svg onClick={() => { setactivePage(activePage - 1) }} role="button" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
                    <path d="M20.1602 11.41L15.5802 16L20.1602 20.59L18.7502 22L12.7502 16L18.7502 10L20.1602 11.41Z" fill="#C4CDD5" />
                    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DFE3E8" />
                </svg>}
                {newArray?.map((node: any, index: number) => {
                    return (
                        <div onClick={() => { setactivePage(node) }} role="button" className={`w-[32px] border rounded-[5px] ${activePage === node ? "text-[#F7941E] border-[#F7941E]" : null}  h-[32px] flex justify-center items-center`}>
                            {node}
                        </div>
                    )
                })}
                {activePage === newArray[newArray.length - 1] ? <svg className='rotate-180 cursor-not-allowed' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <rect width="32" height="32" rx="4" fill="#919EAB" />
                        <path d="M20.1602 11.41L15.5802 16L20.1602 20.59L18.7502 22L12.7502 16L18.7502 10L20.1602 11.41Z" fill="#C4CDD5" />
                    </g>
                </svg> :

                    <svg onClick={() => { setactivePage(activePage + 1) }} role="button" className='rotate-180' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
                        <path d="M20.1602 11.41L15.5802 16L20.1602 20.59L18.7502 22L12.7502 16L18.7502 10L20.1602 11.41Z" fill="#C4CDD5" />
                        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#DFE3E8" />
                    </svg>
                }
            </div>
        </div>
    </>)
}

export default BlogCardSection

// bg-red-600 sm:bg-yellow-600 md:bg-fuchsia-700 lg:bg-teal-600 xl:bg-lime-600